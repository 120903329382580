<template>
  <div
    :class="{
      topNavBox: true,
      color: color,
      border: border,
      noborder: noborder
    }"
  >
    <div class="topbox clearfix allN">
      <a href="javaScript:" class="fl back" @click="seeBack">
        <img src="~img/back@2x.png" alt="" />
      </a>
      <p class="fl">{{ title }}</p>
      <a href="javaScript:" class="fr more" @click="seeMore">
        <img src="~img/share@2x.png" alt="" />
      </a>
    </div>

    <div :class="{ morebox: true, moreShow: moreShow }">
      <div class="moreCon">
        <div class="topbox clearfix allN">
          <a href="javaScript:" class="fl back" @click="seeBack">
            <img src="~img/back@2x.png" alt="" />
          </a>
          <p class="fl">{{ title }}</p>
          <a href="javaScript:" class="fr more" @click="cancle">
            <img src="~img/cancle.png" alt="" />
          </a>
        </div>
        <ul class="clearfix">
          <li class="fl">
            <router-link to="/">
              <img src="~img/home/home.png" alt="" />
              <p>首页</p>
            </router-link>
          </li>
          <li class="fl">
            <router-link to="/collect">
              <img src="~img/home/bookshelf.png" alt="" />
              <p>书架</p>
            </router-link>
          </li>
          <li class="fl">
            <router-link to="/center">
              <img src="~img/home/user.png" alt="" />
              <p>账户</p>
            </router-link>
          </li>
        </ul>

        <router-link to="/recentlyRead" class="recentRead"
          >最近阅读</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    color: Boolean,
    border: Boolean,
    noborder: Boolean
  },
  data() {
    return {
      moreShow: false
    };
  },
  methods: {
    seeBack() {
      this.$router.go(-1);
    },
    seeMore() {
      this.moreShow = true;
    },
    cancle() {
      this.moreShow = false;
    }
  }
};
</script>

<style lang="stylus" scoped>
.topNavBox
    background rgba(255, 255, 255, 1)
    box-shadow 0px 4px 6px 0px rgba(0, 0, 0, 0.05)
    position fixed
    top 0
    left 0
    width 100%
    z-index 11
    &.border
        border-bottom 1px solid #efefef
        box-shadow 0 0 0 0
    &.noborder
        border-bottom 1px solid #fff
        box-shadow 0 0 0 0
.topbox
    height 100px
    line-height 100px
    padding 0 27px
    position relative
    .more
        width 36px
        height 36px
        img
            width 36px
    .back
        width 50px
        height 37px
        line-height 100px
        margin-top 13.5px
        img
            width 18px
            height 37px
    p
        position absolute
        left 0px
        top 0px
        bottom 0px
        right 0px
        margin auto
        text-align center
        font-size 34px
        color #333333
        width 70%
.morebox
    position fixed
    width 100vw
    height 100vh
    z-index 10
    top 0
    background:rgba(0,0,0,0.49);
    transition all 0.17s ease
    opacity 0
    display none
    &.moreShow
        display block
        opacity 1
    .moreCon
         padding 0 0 67px 0
         background #fff
    .topbox
        box-shadow 0 0 0 0
    .more
        img
            width 30px
            height 31px
            margin-top 35px
    ul
        margin 69px 0 50px 112px
        li
            margin-right 142px
            &:nth-child(3n)
                margin-right 0
            img
                width 56px
                height 56px
            p
                text-align center
                color #323232
                font-size 28px
                margin-top 6px
    .recentRead
        width 294px
        height 68px
        background linear-gradient(135deg, rgba(122, 209, 255, 1) 0%, rgba(29, 133, 241, 1) 100%)
        box-shadow 0px 1px 8px 0px rgba(118, 206, 254, 1)
        border-radius 34px
        display block
        line-height 68px
        color #fff
        text-align center
        font-size 28px
        margin 0 auto
</style>
