<template>
  <div class="nonewrap">
    <div class="noneHint">
      <img :src="img" alt="" />
      <p>{{ hint }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: String,
    hint: String
  }
};
</script>

<style lang="stylus" scoped>
.nonewrap
    position relative
    width 100vw
    height calc(100vh - 131px)
    .noneHint
        position absolute
        width 300px
        height 180px
        top 0
        left 0
        right 0
        bottom 0
        margin auto
        img
            width 180px
            display block
            height 120px
            margin 0 auto
        p
            text-align center
            margin-top 10px
</style>
