<template>
  <div class="threeBigRecommend ShelfRecommend normalBox">
    <h3 class="clearfix">
      <div class="shelfName fl clearfix">
        <span class="left-line fl"></span>
        <p class="fl">{{ title }}</p>
      </div>
      <routerLink
        :to="{ path: '/shelf', query: { id: shelfid, name: title } }"
        class="rightMore fr clearfix"
      >
        <span class="fl">更多</span>
        <img class="fl" src="~img/home/Path7@2x.png" alt="" />
      </routerLink>
    </h3>
    <ul class="clearfix shelf1">
      <li v-for="book in shelf" class="shelfItem" :key="book.bookId">
        <router-link :to="'/book/' + book.bookId" v-if="book.book">
          <img v-lazy="book.book.iconUrl" alt="" />
          <p class="ellipsis">{{ book.book.name }}</p>
        </router-link>
      </li>
    </ul>
    <ul class="clearfix shelf2">
      <li v-for="book in booklist" :key="book.bookId">
        <router-link
          :to="'/book/' + book.bookId"
          class="clearfix"
          v-if="book.book"
        >
          <div class="fl tag">{{ book.book.sort }}</div>
          <p class="fl">{{ book.book.name }}</p>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    shelf: Array,
    booklist: Array,
    title: String,
    shelfid: String
  }
};
</script>

<style lang="stylus" scoped></style>
