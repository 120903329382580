<template>
    <div class="pop-con">
        <div class="pop-con-item" v-if="imgType == 'tiaozhuan'">
            <img src="@/static/home/andriod1.png" v-if="phoneType == 'android'"/>
            <img src="@/static/home/ios1.png" v-if="phoneType == 'IOS'"/>
        </div>
        <div class="pop-con-item" v-if="imgType == 'baocun'">
            <img src="@/static/home/andriod2.png" v-if="phoneType == 'android'"/>
            <img src="@/static/home/ios2.png" v-if="phoneType == 'IOS'"/>
            <div class="save-btn">
                <div class="default" @click="saveFun('yes')">我已添加</div>
                <div class="defaultNO" @click="saveFun('no')">去添加</div>
            </div>
        </div>
        <div class="pop-con-item fbsave-con-item" v-if="imgType == 'fbsave'">
            <img src="@/static/home/andriod3.png" v-if="phoneType == 'android'"/>
            <img src="@/static/home/ios3.png" v-if="phoneType == 'IOS'"/>
            <div class="save-btn">
                <div class="defaultNO" @click="saveFun('no')">我知道了</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {setHomeScreen} from "@/utils/storage"
    export default {
        name: "",
        props: ['imgType', 'phoneType'],
        mounted() {

        },
        methods: {
            saveFun (flag) {
                if (flag == 'yes') {
                  setHomeScreen(true)
                }
                this.$emit('closeTG', false)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pop-con{
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .pop-con-item{
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
            }
        }

        .save-btn{
            position: absolute;
            bottom: 144px;
            display: flex;
            -webkit-display: flex;
            justify-content: center;
            div{
                width: 234px;
                height: 78px;
                line-height: 78px;
                text-align: center;
                font-size: 30px;
                font-family: "PingFang SC";
                border-radius: 39px;
                font-weight: 500;
                margin: auto;
                &.defaultNO{
                    border: 2px solid #FFFFFF;
                    color: #fff;
                    margin-left: 108px;
                    margin-right: 70px;
                }
                &.default{
                    background: #E7B249;
                    color: #000;
                }
            }
        }
    }
    .fbsave-con-item{
        .save-btn{
            bottom: 355px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
</style>
