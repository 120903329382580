import Loginelse from "./account/loginelse";
import ThreeBigShelf from "./shelf/threeBigShelf";
import eightShelf from "./shelf/eightShelf";
import threeShelf from "./shelf/threeShelf";
import topNav from "./account/topNav";
import noneHint from "./noneHint";
import nodata from "./nodata";
import popupHome from "./shelf/popupHome";
const comments = {
  install: function(Vue) {
    Vue.component("loginelse", Loginelse);
    Vue.component("ThreeBigShelf", ThreeBigShelf);
    Vue.component("eightShelf", eightShelf);
    Vue.component("threeShelf", threeShelf);
    Vue.component("topNav", topNav);
    Vue.component("noneHint", noneHint);
    Vue.component("nodata", nodata);
    Vue.component("popupHome", popupHome);
  }
};
export default comments;
