let Bath = process.env.VUE_APP_BATH;
let API = process.env.VUE_APP_URL;
let BOOK = process.env.VUE_APP_BOOK;
let USER = process.env.VUE_APP_USER;
let NOTICE = process.env.VUE_APP_NOTICE;
let CONSUME = process.env.VUE_APP_CONSUME;
let CDNCONSUME = process.env.VUE_APP_CDNCONSUME;
let CDNBOOK = process.env.VUE_APP_CDNBOOK;
let SEARCH = process.env.VUE_APP_SEARCH;
// let WRITE = process.env.VUE_APP_WRITE;
export { Bath, API, USER, BOOK, NOTICE, CONSUME, CDNBOOK, CDNCONSUME, SEARCH };
