import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "mint-ui/lib/style.css";
import "lib-flexible/flexible.js";
import "./styles/reset.css";
import "./styles/common.styl";
import { Toast, Swipe, SwipeItem, InfiniteScroll, Actionsheet } from "mint-ui";
Vue.component(Swipe.name, Swipe);
Vue.component(SwipeItem.name, SwipeItem);
Vue.component(Toast.name, Toast);
Vue.component(Actionsheet.name, Actionsheet);
Vue.use(InfiniteScroll);
Vue.prototype.Toast = Toast;
/* 图片懒加载 */
import VueLazyLoad from "vue-lazyload";
Vue.use(VueLazyLoad, {
  error: require("@/static/default.png"),
  loading: require("@/static/default.png")
});
/* 滑动 */
/* import scroll from 'vue-seamless-scroll'
Vue.use(scroll) */
Vue.config.productionTip = false;
if (process.env.VUE_APP_TITLE === "prod") {
  Vue.prototype.imgURL = "https://img.yuanshixx.com";
  Vue.prototype.isShowA = false;
} else if (process.env.VUE_APP_TITLE === "test") {
  Vue.prototype.imgURL = "http://test.img.yuanshixx.cn";
  Vue.prototype.isShowA = true;
} else if (process.env.VUE_APP_TITLE === "uat") {
  Vue.prototype.imgURL = "http://uat.img.yuanshixx.cn";
  Vue.prototype.isShowA = true;
} else {
  Vue.prototype.imgURL = "http://test.img.yuanshixx.cn";
  Vue.prototype.imgURL = "https://img.yuanshixx.cn";
  Vue.prototype.isShowA = true;
}
/* qs */
import qs from "qs";
Vue.prototype.qs = qs;
// ===动态改变标题
import VueWechatTitle from "vue-wechat-title";
Vue.use(VueWechatTitle);
/* 挂在全局组件 */
import comments from "@/components/index.js";
Vue.use(comments);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
