import { finishbook } from 'apiurl/author';
<template>
  <div class="ShelfRecommend normalBox threeShelf">
    <h3 class="clearfix">
      <div class="shelfName fl clearfix">
        <span class="left-line fl"></span>
        <p class="fl">{{ title }}</p>
      </div>
      <routerLink
        :to="{ path: '/shelf', query: { id: shelfid, name: title } }"
        class="rightMore fr clearfix"
      >
        <span class="fl">更多</span>
        <img class="fl" src="~img/home/Path7@2x.png" alt="" />
      </routerLink>
    </h3>
    <ul class="clearfix shelf1">
      <li v-for="(book,index) in shelf" class="threeItem clearfix" :key="index">
        <router-link :to="'/book/' + book.bookId" class="fl" v-if="book.book">
          <img v-lazy="book.book.iconUrl" alt="" />
        </router-link>
        <div class="fl rbox">
          <router-link :to="'/book/' + book.bookId" class="bookname">
            {{ book.book.name }}
          </router-link>
          <div class="bookintro">
            <p class="introCon">{{ book.book.introduce }}</p>
            <div class="clearfix">
              <router-link
                :to="'/book/' + book.bookId"
                class="fl authorName"
                v-if="book.book.author !== null"
                >{{ book.book.author.name }}</router-link
              >
              <div class="fr clearfix">
                <router-link :to="'/book/' + book.bookId" class="tag fl">{{
                  book.book.sort
                }}</router-link>
                <router-link
                  :to="'/book/' + book.bookId"
                  class="fl tag finish"
                  >{{ book.book.finished ? "完结" : "连载中" }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    shelf: Array,
    title: String,
    shelfid: String
  }
};
</script>

<style lang="stylus" scoped>
.introCon
    padding-bottom 16px
    border-bottom 1px solid #E9ECF0
    line-height 40px
    margin-bottom 15px
.authorName
    font-size 22px
    color rgba(172, 175, 194, 1)
    line-height 30px
</style>
