import axios from "axios"; //axios引入
//引入封装的吐司，进度条等
import { Indicator, Toast } from "mint-ui";
import { setToken, getToken } from "utils/storage";
import router from "@/router";
import { setCookie, getCookie } from "utils/core/kernel";
axios.defaults.withCredentials = false; //让ajax携带cookie
// const isPro = Object.is(process.env.NODE_ENV, 'production')
/* var lb = null; //进度条默认不显示
var vuer = null; //vue 对象，传递过来的 */
var msg = ""; //传过来的msg
// 创建axios实例
const service = axios.create({
  //以下为代理跨域的一些baseURL，因为我们有不同的域名，暂时不用
  // baseURL: process.env.BASE_API, // api的base_url
  timeout: 15000 // 请求超时时间
});

// request请求拦截器
service.interceptors.request.use(
  config => {
    //传过来的vue对象（用来进行跳转）
    /*   vuer = config.that; */

    // 设置header
    // config.headers["Access-Control-Expose-Headers"] = "Authorization";
    // 平台类型
    /* config.headers["platform"] = "ys";
    // 客户端类型
    config.headers["client-platform"] = "web";
    //时间戳 */
    if (!getCookie('udid')) {
      var len = 16;
      var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      var maxPos = $chars.length;
      var pwd = '';
      for (var i = 0; i < len; i++) {
          pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      setCookie("udid", pwd, 365);
    }
    config.headers["client-platform"] = 2;
    var timestamp = new Date().getTime();
    config.headers["timestamp"] = timestamp;
    config.headers["lang"] = "zh_TW";
    config.headers["app-name"] = "haiwen";
    config.headers["udid"] = getCookie('udid');
    config.headers["app-version"] = "1.0";
    if (getToken()) {
      config.headers["Authorization"] = "Bearer " + getToken();
    }
    if (config.hds == 1) {
      //上传图片
      config.headers["Content-Type"] = "multipart/form-data";
    } else if (config.hds == 2) {
      //json格式
      config.headers["Content-Type"] = "application/json;charset=UTF-8";
    } else {
      //键值对
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }

    //请求的时候加入进度条（在这里可以传入进入条的图标、msg（封装时已默认加载中），还可以传入特定的值约束，比如：msg ==0 时，不展示）
    msg = config.msg;
    /* if (config.msg != 0) {
      Indicator.open({
        text: "加载中...",
        spinnerType: "fading-circle"
      });
    } */
    Indicator.open({
      text: "加载中...",
      spinnerType: "fading-circle"
    });
    //url.js传过来的所有参数可以统一打印
    console.log("请求参数：url:" + config.url);
    return config;
  },
  error => {
    //网络不通，跳转404页面
    console.log("request跳转404页面：" + error); // 跳转404页面
    Promise.reject(error);
  }
);

// respone返回拦截器
service.interceptors.response.use(
  response => {
    // console.log(response);
    Indicator.close();
    if (response.headers.access_token) {
      setToken(response.headers.access_token);
    }
    //请求成功后隐藏进度条
    if (msg != 0) {
      // if (lb != null) {
      //     lb.close();
      // }
      // Indicator.close();
    }
    const res = response.data;
    if (res !== null) {
      if (res.success || res.code == "newMonthly") {
        // console.log("succ:", response.data);
      } else {
        if (res.code == 500) {
          Toast({
            message: res.message,
            position: "middle",
            duration: 1500
          });
        } else {
          Toast({
            message: res.message,
            position: "middle",
            duration: 1500
          });
        }
        if (
          !(
            res.code == 20030 ||
            res.code == 20031 ||
            res.code == 20000 ||
            res.code == 20066
          )
        ) {
          Toast({
            message: res.message,
            position: "middle",
            duration: 1500
          });
        } else {
          localStorage.clear();
        }
        if (res.redirect) {
          Toast({
            message: res.message,
            position: "middle",
            duration: 1500
          });
          router.push({
            path: "/phoneLogin",
            query: { redirect: router.currentRoute.fullPath }
          });
          localStorage.clear();
        }
      }
      //成功后的成功返回
      return Promise.resolve(res);
    } else {
      console.log("err:", response.data);
      //跳转登录601
      // if (res.result == "0" && (res.code == "601") && (res.develop_code == "DEF-2")) { //未登录
      //     localStorage.clear();
      //     sessionStorage.setItem(store.fromPath, vuer.$route.fullPath)
      //     vuer.$router.push({
      //         name: "login"
      //     })
      // }
      // 成功后的失败返回
      return Promise.reject(res);
    }
  },
  error => {
    Indicator.close();
    //这里只是全局处理不用返回
    if (msg != 0) {
      // if (lb != null) {
      //     lb.close();
      // }
      //   Indicator.close();
    }
    // alert(JSON.stringify(error))
    console.log("error跳转404页面：" + error); // 跳转404页面
    if (
      error.code === "ECONNABORTED" &&
      error.message.indexOf("timeout") !== -1
    ) {
      //console.log('根据你设置的timeout/真的请求超时 判断请求现在超时了，你可以在这里加入超时的处理方案')
      var originalRequest = error.config;
      return service.request(originalRequest); //例如再重复请求一次
    }
    //后台服务器未开Error: Network Error
    if (error == "Error: Network Error") {
      Toast({
        message: "服务器繁忙",
        position: "middle",
        duration: 1500
      });
    } else if (error == "Error: Request failed with status code 504") {
      Toast({
        message: "请求超时",
        position: "middle",
        duration: 1500
      });
    } else if (error == "Error: Request failed with status code 500") {
      Toast({
        message: "服务器繁忙",
        position: "middle",
        duration: 1500
      });
    } else if (error.status == "Error: Request failed with status code 404") {
      Toast({
        message: "资源不存在",
        position: "middle",
        duration: 1500
      });
    } else {
      return Promise.reject({ message: error });
    }
  }
);

export default service;
