import Vue from "vue";
import VueRouter from "vue-router";
const Home = resolve => require(["views/Home.vue"], resolve);
const girl = resolve => require(["views/channel/girl.vue"], resolve);
const boy = resolve => require(["views/channel/boy.vue"], resolve);
const bookbox = resolve => require(["views/channel/bookbox.vue"], resolve);
const moreShelf = resolve => require(["views/channel/moreShelf.vue"], resolve);
const search = resolve => require(["views/channel/search.vue"], resolve);
const sortbox = resolve => require(["views/channel/sort.vue"], resolve);
const userAgree = resolve => require(["views/about/userAgree.vue"], resolve);
const privacy = resolve => require(["views/about/privacy.vue"], resolve);
const logout = resolve => require(["views/about/logout.vue"], resolve);
const recentlyRead = resolve =>
  require(["views/channel/recentlyRead.vue"], resolve);
/* 登陆 */
const phoneLogin = resolve =>
  require(["views/account/phoneLogin.vue"], resolve);
const login = resolve => require(["views/account/login.vue"], resolve);
const forget = resolve =>
  require(["views/account/repeatPassword.vue"], resolve);
const loginLoading = resolve =>
  require(["views/account/loginLoading.vue"], resolve);
/* 个人中心 */
const center = resolve => require(["views/personal/center.vue"], resolve);
const history = resolve => require(["views/personal/history.vue"], resolve);
const collect = resolve => require(["views/personal/collect.vue"], resolve);
const message = resolve => require(["views/personal/message.vue"], resolve);
const myAccount = resolve => require(["views/personal/myAccount.vue"], resolve);
const wallet = resolve => require(["views/personal/money/wallet"], resolve);
const rechange = resolve =>
  require(["views/personal/money/rechangeRecord"], resolve);
const consume = resolve =>
  require(["views/personal/money/consumeRecord"], resolve);
const consumeone = resolve =>
  require(["views/personal/money/consumeone.vue"], resolve);
const consumebook = resolve =>
  require(["views/personal/money/consumebook.vue"], resolve);
const toup = resolve => require(["views/personal/money/toup"], resolve);
const alipay = resolve => require(["views/personal/money/alipay"], resolve);
const nickName = resolve => require(["views/personal/nickName.vue"], resolve);
const bindPhone = resolve => require(["views/personal/bindPhone.vue"], resolve);
const setPassword = resolve =>
  require(["views/personal/setPassword.vue"], resolve);
const changePassword = resolve =>
  require(["views/personal/changePassword.vue"], resolve);
/* 书籍详情 */
const detail = resolve => require(["views/readPage/detail.vue"], resolve);
const catalog = resolve => require(["views/readPage/catalog.vue"], resolve);
const chapter = resolve => require(["views/readPage/chapter.vue"], resolve);
/* 客户端页面 */
const payhint = resolve =>
  require(["views/client/prompt/payhint.vue"], resolve);
Vue.use(VueRouter);
import { getToken } from "utils/storage";
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "嗨文小说-小说阅读网|好看的小说|小说推荐",
      // keepAlive: true
    }
  },
  {
    path: "/girl",
    name: "girl",
    component: girl,
    meta: {
      title: "嗨文小说-女频小说阅读网|好看的小说|小说推荐",
      keepAlive: true
    }
  },
  {
    path: "/boy",
    name: "boy",
    component: boy,
    meta: {
      title: "嗨文小说-男频小说阅读网|好看的小说|小说推荐",
      keepAlive: true
    }
  },
  {
    path: "/book/:id",
    name: "detail",
    component: detail,
    meta: {
      title: "书籍详情"
    }
  },
  {
    path: "/catalog/:id",
    name: "catalog",
    component: catalog,
    meta: {
      title: "目录"
    }
  },
  {
    path: "/book/:id/:chapterId",
    name: "chapter",
    component: chapter,
    meta: {
      title: "章节内容"
    }
  },
  {
    path: "/recentlyRead",
    name: "recentlyRead",
    component: recentlyRead,
    meta: {
      title: "最近阅读",
      login: true
    }
  },
  {
    path: "/sort",
    name: "sortbox",
    component: sortbox,
    meta: {
      title: "分类",
      keepAlive: true
    }
  },
  {
    path: "/search",
    name: "search",
    component: search,
    meta: {
      title: "搜索"
    }
  },
  {
    path: "/sort/:id",
    name: "bookbox",
    component: bookbox,
    meta: {
      title: "分类详情"
    }
  },
  {
    path: "/shelf",
    name: "moreShelf",
    component: moreShelf,
    meta: {
      title: "嗨文小说-男频小说阅读网|好看的小说|小说推荐"
    }
  },
  {
    path: "/myAccount",
    name: "myAccount",
    component: myAccount,
    meta: {
      title: "我的账户",
      login: true
    }
  },
  {
    path: "/wallet",
    name: "wallet",
    component: wallet,
    meta: {
      title: "钱包",
      login: true
    }
  },
  {
    path: "/toup",
    name: "toup",
    component: toup,
    meta: {
      title: "充值",
      login: true
    }
  },
  {
    path: "/alipay",
    name: "alipay",
    component: alipay,
    meta: {
      title: "充值",
      login: true
    }
  },
  {
    path: "/rechange",
    name: "rechange",
    component: rechange,
    meta: {
      title: "充值记录",
      login: true
    }
  },
  {
    path: "/consume",
    name: "consume",
    component: consume,
    meta: {
      title: "消费记录",
      login: true
    }
  },
  {
    path: "/consume/:id",
    name: "consumeone",
    component: consumeone,
    meta: {
      title: "章节购买",
      login: true
    }
  },
  {
    path: "/consume/:id/:bookId",
    name: "consumebook",
    component: consumebook,
    meta: {
      title: "书籍",
      login: true
    }
  },
  {
    path: "/consume",
    name: "consume",
    component: consume,
    meta: {
      title: "消费记录",
      login: true
    }
  },
  {
    path: "/history",
    name: "history",
    component: history,
    meta: {
      title: "浏览记录",
      login: true
    }
  },
  {
    path: "/collect",
    name: "collect",
    component: collect,
    meta: {
      title: "我的收藏",
      login: true
    }
  },
  {
    path: "/nickName",
    name: "nickName",
    component: nickName,
    meta: {
      title: "修改昵称",
      login: true
    }
  },
  {
    path: "/bindPhone",
    name: "bindPhone",
    component: bindPhone,
    meta: {
      title: "绑定手机号",
      login: true
    }
  },
  {
    path: "/loginLoading/qq",
    name: "loginLoading",
    alias: "/loginLoading/wx",
    component: loginLoading,
    meta: {
      keepAlive: false,
      title: "正在登录-嗨文小说"
    }
  },
  {
    path: "/setPassword",
    name: "setPassword",
    component: setPassword,
    meta: {
      title: "设置密码",
      login: true
    }
  },
  {
    path: "/forget",
    name: "forget",
    component: forget,
    meta: {
      title: "重置密码"
    }
  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: changePassword,
    meta: {
      title: "修改密码",
      login: true
    }
  },
  {
    path: "/message",
    name: "message",
    component: message,
    meta: {
      title: "消息通知",
      login: true
    }
  },
  {
    path: "/phoneLogin",
    name: "phoneLogin",
    component: phoneLogin,
    meta: {
      title: "三方登录"
    }
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: "登录"
    }
  },
  {
    path: "/center",
    name: "center",
    component: center,
    meta: {
      title: "个人中心",
      login: true
    }
  },
  {
    path: "/userAgree",
    name: "userAgree",
    component: userAgree,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: privacy,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: logout,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/payhint",
    name: "payhint",
    component: payhint,
    meta: {
      keepAlive: true
    }
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.login && !getToken()) {
    //true则需要登录
    next({
      name: "phoneLogin",
      query: {
        redirect: to.fullPath
      }
    });
    return;
  } else {
    next();
  }
});

export default router;
