/* rsa加密 */
import { JSEncrypt } from "jsencrypt";
let CryptoJS = require("crypto-js")
let MD5 = require("crypto-js/md5")
const IV = '0102030405060708'
/* 加密手机号邮箱 */
export function encryptStr(str, type) {
  var rsa = new JSEncrypt();
  var rsaKey =
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCrIqFb8i6Qw9ETZilOQNP8eTdV0UJm1ToOa5G146lF/Ik14ZNwx0hmdK4a8X35DAa2fmYlZD5Jt+1qzh6GsHI6n119NSQgH5QKaQ0RKeDxkJux/RZv0bcEE9dqP0erX2DtMJ46Rt5eT2Qnp7myTUF57+4+d6LEX0bObQ5BdrGWgwIDAQAB";
  // 身份证
  if (type == 1) {
    rsaKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC57Xz7boiVNkDcpHr5nSK/xhoxJBv34Es5ZAKCe2k0K2lMXdNTTvwkaWYJnaMho3tM9m1ZHY22HFiekrhNhsMXvDxTCgeXgHu7PpHfkQSxPRPlg2ST/N9le8vyvzoIaw8A1Ej9TQW4XMhB9G5ZIwLemP+Ez3nct5/TqIMyMF/ftwIDAQAB`;
  } else if (type == 2) {
    //银行卡
    rsaKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCVISPOlWtV/uLq4kSSvmuyfgJ1KsB0DgYXPls0Wcy05xxUkA7gcxD3q8ROD/2eKr/uwpC+ij9uLuAm4LBtbkcpfWicmBtGmVe9GWHV+6mHXOkkg4Z+u3p0cM3POH2IcwKNSmp/ba5rKy605c+wznluD8p6Qoapb80Mp8+ixkObDwIDAQAB`;
  }
  rsa.setPublicKey(rsaKey);
  let data = rsa.encrypt(str);
  return data;
}
/* 解密 */
export function decrypt(str, type) {
  var rsa = new JSEncrypt();
  var rsaKey = "MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAK1lGX4ScKT7pMlo7akNzU4U0N5oFgaR+0aCwQPa8WgnFmYDawMtSY5KhBygYkeHE+C7wBXQmdbGkSK0f8Y07STxx/5BjezTqbpwiWI8t8Mk7PG5dCbR47C+UU4Q7E8jzjh0Ixpm998Lxjzv2psT+PAcEdjv2wE/8BIZP8hz+uaZAgMBAAECgYB2Ig0OMa4BGVkZx50zsi2P5uquvCdVbpvNXEP4RnB2TzsZYXbFAVO919LOVg7fQI4wbHnf4vEMvNgg1uy+s0xk34IcrmQl0X4YvzKkQsXm2NEDEPAFG/JvKY9KGWBi1+V0wDKS9qpIQjPt4c+LZmOVVPB0qaHGSxVEOjlQtsoBaQJBAPFNK/rbx9J0p3LRcmif40VvG2KjbVH4RqGXjRrTOUQqAstdKmHF3atbw6Kimrl8gmrHgPDvheTaQ45qVfAtLHMCQQC39QA1vjHz9qWK/rm7D1hi/tpOlliUwfduXihX+itToMnq6yQnSntnwPIbjBH6g2zInYzhhBuLXRbj7FpadgnDAkEAiG33lcg5vHYUTsi5BQqRdyqQsz5vis7zMF39e9M9J5sRJWYxlkJHSKGDhSqmjIUnP7Q45QCoFXKKi7T3jeocnQJAJC2qCefK+TkD2683JePhtS58DsKgzzjKick40/yecscHjw0JwjRyyrf2pZZJnuQkC9x4bY3GwQTg0JiWOH5IcwJBAIXosBTmCWGXd7v8dYW7WfPi03L/tZrIZ3Pglhts2UBFdUqF4f1dwq37+nDzCXJGntbuUvJjMtZ5f+gbEQ77N3w=";
  rsa.setPrivateKey(rsaKey);
  let data = rsa.decrypt(str);
  return data;
}
/* 解密 */
export function asedecrypt (word, keyStr) {
  keyStr = keyStr ? keyStr : "1grLx91U40VawzhRAm7E";
  let key = CryptoJS.enc.Utf8.parse(keyStr);
  let decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
/**
 * 判断是否为空
 */
export function isNull(val) {
  if (typeof val === "boolean") {
    return false;
  }
  if (val instanceof Array) {
    if (val.length === 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === "{}") return true;
  } else {
    if (
      val === "null" ||
      val == null ||
      val === "undefined" ||
      val === undefined ||
      val === ""
    )
      return true;
  }
  return false;
}
/**
 *  六位以上 字母数字符号随意组合   至少两种
 */
export function isPwd(val) {
  let reg = new RegExp(
    /^(?![0-9]+$)(?![a-zA-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-zA-Z]|[0-9]){6,18}$/
  );
  if (val == null || val.length < 6) {
    return false;
  }
  if (reg.test(val)) {
    return true;
  } else {
    return false;
  }
}
/**
 *  验证手机号：11位，以1开头，其余10位是数字
 */
export function isMobile(val) {
  let reg = new RegExp(/^0?(13|15|17|18|14)[0-9]{9}$/);
  if (reg.test(val)) {
    return true;
  } else {
    return false;
  }
}

/*
 * 验证qq
 */
export function isQQ(val) {
  let reg = new RegExp(/[1-9][0-9]{4,10}/);
  if (reg.test(val)) {
    return true;
  } else {
    return false;
  }
}
/* 验证昵称
 */
export function isNick(val) {
  let reg = new RegExp(/^[\u4E00-\u9FFF\w]{2,10}$/);
  if (reg.test(val)) {
    return true;
  } else {
    return false;
  }
}
/**
 *  验证邮箱
 */
export function isEmail(val) {
  let reg = new RegExp(/^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$/);
  if (val == null || val.length < 6) {
    return false;
  }
  if (reg.test(val)) {
    return true;
  } else {
    return false;
  }
}
/*
 验证身份证号
*/
export function isidNo(val) {
  let reg = new RegExp(
    /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
  );
  if (reg.test(val)) {
    return true;
  } else {
    return false;
  }
}
/* 验证银行卡
 */
export function isAccount(val) {
  let reg = new RegExp(/^[0-9]*$/);
  if (reg.test(val)) {
    return true;
  } else {
    return false;
  }
}
/**
 * 验证是否登录
 */
export function isLogin() {
  let token = localStorage.getItem("rock-Token");
  if (isNull(token)) {
    return false;
  } else {
    return true;
  }
}
/* 设置cookie */
export function setCookie(c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
}

export function getCookie(name) {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) return arr[2];
  else return null;
}

export function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}
