<template>
  <div class="threeBigRecommend ShelfRecommend normalBox">
    <h3 class="clearfix">
      <div class="shelfName fl clearfix">
        <span class="left-line fl"></span>
        <p class="fl">{{ title }}</p>
      </div>
      <routerLink
        :to="{ path: '/shelf', query: { id: shelfid, name: title } }"
        class="rightMore fr clearfix"
      >
        <span class="fl">更多</span>
        <img class="fl" src="~img/home/Path7@2x.png" alt="" />
      </routerLink>
    </h3>
    <ul class="clearfix shelf1">
      <li
        v-for="(book, index) in shelf"
        class="shelfItem eightItem"
        :key="index"
      >
        <template v-if="book.book">
          <router-link :to="'/book/' + book.bookId" v-if="book.book">
            <img v-lazy="book.book.iconUrl" alt="" />
            <p class="ellipsis">{{ book.book.name }}</p>
          </router-link>
        </template>
        <template v-else>
          <router-link :to="'/book/' + book.id">
            <img v-lazy="book.iconUrl" alt="" />
            <p class="ellipsis">{{ book.name }}</p>
          </router-link>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    shelf: Array,
    title: String,
    shelfid: String
  }
};
</script>

<style></style>
