const envType = process.env.VUE_APP_TITLE
let baseURL = {}
if (envType === 'develop' || envType === 'test') {
  baseURL.apiURL = 'https://test-biz.blackrockread.com'
  baseURL.searchApi = 'https://test-search.blackrockread.com'
} else if (envType === 'prod') {
  baseURL.apiURL = 'https://biz.blackrockread.com'
  baseURL.searchApi = 'https://search.blackrockread.com'
} else {
  baseURL.apiURL = ''
}

export default baseURL
