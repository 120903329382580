<template>
  <div class="loginelse">
    <div class="loginbox">
      <!-- <a href="javaScript:">
        <img src="~img/account/wx@2x.png" alt="" />
      </a> -->
      <a href="javaScript:" @click="changelogin">
        <img
          v-if="this.$route.path !== '/phoneLogin'"
          src="~img/account/ph@2x.png"
          alt=""
        />
        <img
          v-if="this.$route.path == '/phoneLogin'"
          src="~img/account/user.png"
          alt=""
        />
      </a>
      <a :href="qqurl">
        <img src="~img/account/QQ@2x.png" alt="" />
      </a>
    </div>
    <p>
      * 点击登录代表同意【嗨文】<router-link to="/userAgree"
        >用户协议</router-link
      >
      和
      <router-link to="/privacy">隐私政策</router-link>
    </p>
  </div>
</template>

<script>
import { USER } from "apiurl/index.js";
export default {
  data() {
    return {
      qqurl: ""
    };
  },
  methods: {
    changelogin() {
      if (this.$route.path !== "/phoneLogin") {
        if (this.$route.query.redirect) {
          this.$router.replace(
            `/phoneLogin?redirect=${this.$route.query.redirect}`
          );
        } else {
          this.$router.replace(`/phoneLogin`);
        }
      } else {
        if (this.$route.query.redirect) {
          this.$router.replace(`/login?redirect=${this.$route.query.redirect}`);
        } else {
          this.$router.replace(`/login`);
        }
      }
    }
  },
  mounted() {
    let backUrl = "/";
    if (this.$route.query.redirect) {
      backUrl = this.$route.query.redirect;
    }
    this.qqurl = `${USER}/third/auth/login?thirdType=2&backUrl=${backUrl}&site=2`;
  }
};
</script>

<style lang="stylus" scoped>
.loginbox
    margin 0 240px 49px
    a
        margin-right 137px
        &:nth-child(2)
            margin-right 0
        img
            width 58px
            height 58px
p
    text-align center
    color #C3C3C3
    font-size 20px
    a
        color #3066AF
        text-decoration underline
</style>
