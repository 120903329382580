import requset from "utils/request";
import baseURL from "./env";
/* 登录 */
export function login(params) {
  return requset({
    url: baseURL.apiURL + "/auth/login",
    method: "POST",
    params
  });
}
/* 登出 */
export function logout() {
  return requset({
    url: baseURL.apiURL + "/user/auth/logout",
    method: "POST"
  });
}
/* 手机号密码登录 */
export function phonelogin(params) {
  return requset({
    url: baseURL.apiURL + "/user/auth/code/login",
    method: "POST",
    params
  });
}
/* 手机号密码登录 */
export function authfacebook(params) {
  return requset({
    url: baseURL.apiURL + "/user/third/auth/native/facebook",
    method: "POST",
    params
  });
}
/* FB登录 */
export function facebooklogin(params) {
  return requset({
    url: baseURL.apiURL + "/user/third/auth/app/facebook",
    method: "get",
    params
  });
}/* 获取手机验证码 */
export function getCode(params) {
  return requset({
    url: `${baseURL.apiURL}/user/auth/mobile/captcha`,
    method: "get",
    params
  });
}
/* 重置密码 */
export function forget(params) {
  return requset({
    url: baseURL.apiURL + "/account/pwd/reset",
    method: "POST",
    params
  });
}
/* 正在登陆qq */
export function loginstatic(params) {
  return requset({
    url: baseURL.apiURL + "/third/auth/login/qq",
    method: "POST",
    params
  });
}
/* 正在登陆qq */
export function weixinstatic(params) {
  return requset({
    url: baseURL.apiURL + "/third/auth/login/wx",
    method: "POST",
    params
  });
}
/* 用户设置 */
// 获取用户信息
export function getuserInfo() {
  return requset({
    url: `${baseURL.apiURL}/user/account/info`,
    method: "get"
  });
}
/* 获取某本书的阅读状态---个人--非cdn */
export function readstatus(params) {
  return requset({
    url: `${baseURL.apiURL}/user/read/record/status`,
    method: "GET",
    params
  });
}
/* 阅读历史 */
// 阅读历史
export function readhistory(params) {
  return requset({
    url: `${baseURL.apiURL}/user/read/record/list`,
    method: "GET",
    params
  });
}
// 移除阅读
export function deletehistory(params) {
  return requset({
    url: `${baseURL.apiURL}/user/read/record/del`,
    method: "POST",
    params
  });
}
/* 添加阅读记录 */
export function addreadhistory(params) {
  return requset({
    url: `${baseURL.apiURL}/user/read/record/add`,
    method: "POST",
    params
  });
}
// 加入书架
export function addshelf(params) {
  return requset({
    url: `${baseURL.apiURL}/user/user/shelf/add`,
    method: "POST",
    params
  });
}
// 获取书架列表
export function getshelf(params) {
  return requset({
    url: `${baseURL.apiURL}/user/user/shelf/list`,
    method: "GET",
    params
  });
}

// 移除书架
export function deleteshelf(params) {
  return requset({
    url: `${baseURL.apiURL}/user/user/shelf/del`,
    method: "POST",
    params
  });
}
/* 上传头像 */
export function uploadimg(data) {
  return requset({
    url: `${baseURL.apiURL}/user/account/icon/save`,
    method: "POST",
    hds: 1,
    data
  });
}
/* 修改性别 */
export function changeGender(params) {
  return requset({
    url: `${baseURL.apiURL}/user/account/gender/modify`,
    method: "POST",
    params
  });
}
// 修改用户昵称
export function changeUsername(params) {
  return requset({
    url: `${baseURL.apiURL}/user/account/username/modify`,
    method: "post",
    params
  });
}
// 绑定手机号
export function bindPhone(params) {
  return requset({
    url: `${baseURL.apiURL}/user/account/bind/mobile`,
    method: "post",
    params
  });
}
// 设置密码
export function setPassword(data) {
  return requset({
    url: `${baseURL.apiURL}/account/mobile/pwd/set/`,
    method: "post",
    data
  });
}
// 修改密码
export function changePassword(params) {
  return requset({
    url: `${baseURL.apiURL}/account/pwd/modify`,
    method: "post",
    params
  });
}
/* 是否开启自动订阅 */
export function buyAuto(params) {
  return requset({
    url: `${baseURL.apiURL}/user/read/record/autoSubScribe`,
    method: "POST",
    params
  });
}
/* 最新记录 */
export function lastHistory(params) {
  return requset({
    url: `${baseURL.apiURL}/user/read/record/last`,
    method: "GET",
    params
  });
}
