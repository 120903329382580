const TokenKey = "rock-Token";
const homeScreen = "";

export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  return localStorage.removeItem(TokenKey);
}
export function getHomeScreen() {
  return localStorage.getItem("homeScreen");
}
export function setHomeScreen(homeScreen) {
  return localStorage.setItem("homeScreen", homeScreen);
}
export function removeHomeScreen() {
  return localStorage.removeItem("homeScreen");
}
export function getwriter() {
  return localStorage.getItem("writer");
}

export function setwriter(writer) {
  return localStorage.setItem("writer", writer);
}

export function removewriter() {
  return localStorage.removeItem("writer");
}

export function getUserId() {
  return localStorage.getItem("uid");
}

export function setUserId(id) {
  return localStorage.setItem("uid", id);
}

export function removeUserId() {
  return localStorage.removeItem("uid");
}
export function getinfo() {
  return localStorage.getItem("info");
}

export function setinfo(info) {
  return localStorage.setItem("info", info);
}

export function removeinfo() {
  return localStorage.removeItem("info");
}
export function getmobile() {
  return localStorage.getItem("mobile");
}

export function setmobile(mobile) {
  return localStorage.setItem("mobile", mobile);
}

export function removemobile() {
  return localStorage.removeItem("mobile");
}
export function getUserName() {
  return localStorage.getItem("un");
}

export function setUserName(un) {
  return localStorage.setItem("un", un);
}

export function removeUserName() {
  return localStorage.removeItem("un");
}
/* 存储作者书的id */
export function getAbookid() {
  return localStorage.getItem("Abookid");
}

export function setAbookid(Abookid) {
  return localStorage.setItem("Abookid", Abookid);
}

export function removeAbookid() {
  return localStorage.removeItem("Abookid");
}
/* 储存书的名字 */
export function getAbookName() {
  return localStorage.getItem("AbookName");
}
export function setAbookName(AbookName) {
  return localStorage.setItem("AbookName", AbookName);
}

export function removeAbookName() {
  return localStorage.removeItem("AbookName");
}
/* 作者的书是否可选收费 */
export function gettoll() {
  return localStorage.getItem("toll");
}

export function settoll(toll) {
  return localStorage.setItem("toll", toll);
}

export function removetoll() {
  return localStorage.removeItem("toll");
}
// 登录用户是否是重置密码后的第一次登录状态
export function setIsReset(val) {
  return localStorage.setItem("isReset", val);
}

export function getIsReset() {
  return localStorage.getItem("isReset");
}

export function removeIsReset() {
  return localStorage.removeItem("isReset");
}
// 清空localstorage数据
export function clearStorage() {
  localStorage.clear();
}
export function setCookie(name, value) {
  var Days = 30;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 30);
  document.cookie =
    name +
    "=" +
    escape(value) +
    ";expires=" +
    exp.toGMTString() +
    ";domain=.yuanshi.com";
}
