import {
  setUserId,
  getUserId,
  removeUserId,
  setUserName,
  getUserName,
  removeUserName,
  clearStorage,
  getinfo,
  setinfo,
  removeinfo,
  removeToken,
  getwriter,
  setwriter
} from "utils/storage";
let info = JSON.parse(getinfo());
import { login, logout } from "apiurl/user";
const user = {
  state: {
    id: getUserId(),
    name: getUserName(),
    img: info,
    writer: getwriter
  },
  mutations: {
    SET_ID: (state, id) => {
      state.id = id;
    },
    SET_INFO: (state, info) => {
      state.img = info;
    },
    SET_NAME: (state, nu) => {
      state.name = nu;
    },
    SET_WRTER: (state, istrue) => {
      state.writer = istrue;
    }
  },
  actions: {
    //   登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then(response => {
            if (response.success) {
              setUserId(response.data.id);
              setUserName(response.data.name);
              setwriter(response.data.writer);
              setinfo(JSON.stringify(response.data));
              commit("SET_ID", response.data.id);
              commit("SET_NAME", response.data.name);
              commit("SET_INFO", response.data);
              commit("SET_WRTER", response.data.writer);
            }
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(response => {
            if (response.success) {
              commit("SET_ID", "");
              commit("SET_NAME", "");
              commit("SET_INFO", "");
              removeToken();
              removeUserId();
              removeUserName();
              removeinfo();
              clearStorage();
              resolve(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /* 储存信息 */
    saveInfo({ commit }, info) {
      setUserId(info.id);
      setUserName(info.name);
      setwriter(info.writer);
      setinfo(JSON.stringify(info));
      commit("SET_ID", info.id);
      commit("SET_NAME", info.name);
      commit("SET_INFO", info);
      commit("SET_WRTER", info.writer);
    },
    /* 改变作家状态 */
    asWriter({ commit }) {
      commit("SET_WRTER", true);
      setwriter(true);
    }
  }
};
export default user;
