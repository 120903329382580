<template>
    <div class="el-no-data">
        <img src="@/static/nonebook.png" class="empty-img"/>
        <slot name="datatitle"></slot>
    </div>
</template>

<script>
    export default {
        name: "",
        props: ['imgtype']
    }
</script>

<style lang="scss" scoped>
    .el-no-data{
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #000;
        .empty-img{
            width: 351px;
            height: 234px;
        }
        .nodata-tip{
            text-align: center;
            margin-top: 10px;
            font-size: 30px;
            color: #999;
        }
    }
</style>
